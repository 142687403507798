<template>
  <CFooter :fixed="false">
      <div class="mr-auto ml-4">
        <span>Copyright &copy; Howcost Inc. All rights reserved. </span>
      </div>
    <div class="ml-auto mr-4">
      <span><a href="mailto:developer@howcost.co.kr">developer@howcost.co.kr</a></span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
