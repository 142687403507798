<template>
  <div class="c-app w-100">
    <TheSidebar :key="sideBarKey"/>
    <CWrapper>
      <TheHeader :key="headerKey"/>
      <div class="c-body">
        <main class="c-main">
          <BContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </BContainer>
        </main>
        <TheFooter/>
      </div>
    </CWrapper>
  </div>
</template>

<script>
  import TheSidebar from './TheSidebar'
  import TheHeader from './TheHeader'
  import TheFooter from './TheFooter'

  export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  data(){
    return {
      sideBarKey: 1234567,
      headerKey: 1,
    }
  },
  created(){

  },
  methods: {
    reRenderSideBar() {
      this.sideBarKey = Math.floor((Math.random() * 10000));
      console.log( "-----------------------------------reRenderSideBar-------------------------------- key: ", this.sideBarKey );
    },
    reRenderHeader() {
      this.headerKey = Math.floor((Math.random() * 1000000));
      console.log( "-----------------------------------reRenderHeader--------------------------------- key: ", this.headerKey  );
    },

  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
