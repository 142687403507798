<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />

    <CHeaderBrand class="mx-auto d-lg-none" to="/admin-x">
    </CHeaderBrand>

    <CHeaderNav class="d-md-down-none mr-auto">
    </CHeaderNav>

    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt :key="accountKey"/>
        <BNavItemDropdown
          id="user-nav"
          :text="user.user_id"
        >
          <BDropdownItem @click="clickUserInfo">정보 변경</BDropdownItem>
          <BDropdownDivider/>
          <BDropdownItem @click="clickLogout">로그아웃</BDropdownItem>
        </BNavItemDropdown>

    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
  import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

  export default {
    name: 'TheHeader',
    components: {
      TheHeaderDropdownAccnt
    },
    data(){
      return {
          user: null,
          accountKey: 0,
      }

    },
    created(){
      this.user = $user;
      this.accountKey = Math.floor((Math.random() * 100000));
    },
    methods: {
      clickLogout : function(){
          this.$store.dispatch('LOGOUT');
          this.$router.push({ path: '/admin-x/login'  });
      },
        clickUserInfo: function() {
            this.$router.push({ path: '/admin-x/user-info'  });
        },

        reRenderAvatar() {
            this.accountKey = Math.floor((Math.random() * 100000));
            console.log( "-----------------------------------reRenderAvatar--------------------------------- key: ", this.accountKey  );
        },
    },
}
</script>
